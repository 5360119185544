<template>
  <el-row>
    <el-col :span="8">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="收件人姓名">
          <el-input id="mobile" v-model="form.name" style="width: 229px;" placeholder="收件人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收件人电话">
          <el-input id="mobile" v-model="form.mobile" style="width: 229px;" placeholder="收件人电话"></el-input>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input id="mobile" v-model="form.exnumber" style="width: 229px;" placeholder="快递单号"></el-input>
        </el-form-item>
        <el-form-item label="快递公司" style="align-content: flex-start">
          <el-select v-model="form.express" placeholder="请选择快递公司" style="width: 229px;" @change="changeSelect">
            <el-option v-for="(k,v) in Exlist" :key=v :label=k.exname :value=k.exname></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">发送短信</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="16">
      <TodaySended ref="mychild"></TodaySended>
    </el-col>
  </el-row>

</template>

<script>
import ExpressApiService from "@/api/ExpressApi";
import SmsApiService from "@/api/SmsApi"
import {ElMessage, ElNotification} from "element-plus";
import {checkPhone} from "../utils/auth";
import TodaySended from "@/components/TodaySended";

export default {
  name: "SendSms",
  data() {
    return {
      form: {
        mobile: '',
        express: "",
        exnumber: "",name:"",
      },
      Exlist: [],
    }
  },
  components: {
    TodaySended,
  },

  beforeMount() {
    var that = this;
    that.getExlist()
  },
  methods: {
    reset() {
      this.form = {
        mobile: '',
        express: "",
        exnumber: "",
        name:"",
      }
    },
    changeSelect() {
      console.info(this.exvalue)
    },
    onSubmit() {
      console.log('submit!')
      console.info(this.form, this.exvalue)
      //收件人手机号验证
      if (!checkPhone(this.form.mobile)) {
        ElMessage.error("收件人手机号码格式错误，请输入正确的手机号码")
        return
      }
      if (this.form.name === "") {
        ElMessage.error("快递单号不能为空，请输入单号")
        return
      }
      console.log(this.form.exnumber)
      //运单号空
      if (this.form.exnumber === "") {
        ElMessage.error("快递单号不能为空，请输入单号")
        return
      }
      //运单号长度有问题
      if (this.form.exnumber.length < 5) {
        ElMessage.error("快递单号长度有问题，请核实单号")
        return
      }
      //
      if (this.form.express === "") {
        ElMessage.error("请选择快递或者物流公司")
        return;
      }

      SmsApiService.SendSms(this.form).then(res => {
        console.info(res.data.message)
        ElNotification.success(res.data.message);
        this.$refs.mychild.getTodaySendedSms();
      }).catch(Ex => {
        console.info(Ex.response)
        ElMessage.error("some thing wrong")
      })
    },
    getExlist() {
      let that = this;
      ExpressApiService.getExpressList().then(res => {
        console.info(res.data)
        that.Exlist = res.data
      }).catch(e => {
        console.info(e.response)
      })
    }
  },
}
</script>

<style scoped>
#mobile {
  width: 200px;
}
</style>
