import {createStore} from 'vuex'

export default createStore({
    state: {
        token: ""
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        }
    },
    actions: {
        SetUserToken(context) {
            context.commit('setToken')
        }
    },
    getters: {
        token: state => {
            return state.token;
        }
    },
    modules: {}
})
