<template>
  <div>

    <el-form :inline="true" :model="nameform" class="demo-form-inline">
      <el-form-item label="根据收件人查询">
        <el-input v-model="nameform.name" placeholder="收件人姓名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="nameSubmit">查询</el-button>
      </el-form-item>
    </el-form>

    <el-form :inline="true" :model="exform" class="demo-form-inline">
      <el-form-item label="根据快递单查询">
        <el-input v-model="exform.exnumber" placeholder="快递单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="exSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  <hr>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="多条件查询 三者至少有一个条件要选择"></el-form-item>
      <el-form-item label="收件号码">
        <el-input v-model="form.destnum" placeholder="收件人电话"></el-input>
      </el-form-item>
      <el-form-item label="操作人员">
        <el-input v-model="form.sender" placeholder="短信发送人员"></el-input>
      </el-form-item>
      <el-date-picker v-model="form.monthdate" format="YYYY/MM/DD"
                      placeholder="选择要查询的日期"
                      type="date"
                      value-format="YYYY-MM-DD" @change=changeDate>
      </el-date-picker>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <hr>
    <el-table :data="tableData" :default-sort="{ prop: 'sendtime', order: 'descending' }" border
              style="width: 100%">
      <el-table-column label="收件人姓名" prop="destname"/>
      <el-table-column label="收件人电话" :formatter=phoneformat prop="destnum"/>
      <el-table-column label="快递单号" prop="exnumber" width="180"/>
      <el-table-column label="快递公司" prop="excompany" width="180"/>
      <el-table-column :formatter=timeformat label="发送时间" prop="sendtime" sortable/>
      <el-table-column label="发送人" prop="sender"/>
    </el-table>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import SmsApiService from "@/api/SmsApi";

export default {
  name: "listExpress", data() {
    return {
      exform: {
        exnumber: ""
      },

      nameform: {
        name: ""
      },
      form: {
        destnum: '',
        sender: '',
        monthdate: "",
      },
      tableData: [],
    }
  },
  methods: {
    timeformat: function (row, column, cellValue, index) {
      console.info(row, column, cellValue, index)
      return this.$moment(cellValue).format('YYYY MMMM Do, h:mm:ss a');
    },
    phoneformat:function (row, column, cellValue, index) {
      console.info(row, column, cellValue, index)
      let res= cellValue.substring(0,3)+"****"+cellValue.substring(6,10)
      return res
    },
    changeDate() {
    },
    nameSubmit(){
      let form = this.nameform;
      if (form.name == "" || form.name == null) {
        ElMessage.error("收件人姓名不能为空")
        return
      }
      let d = "cname=" + form.name
      SmsApiService.getByCname(d).then(res => {
        console.info(res.data)
        this.tableData = res.data;
      })
    },
    exSubmit() {
      let form = this.exform;
      if (form.exnumber == "" || form.exnumber == null) {
        ElMessage.error("单号不能为空")
        return
      }
      let d = "exnumber=" + form.exnumber
      SmsApiService.getByExnumber(d).then(res => {
        console.info(res.data)
        this.tableData = res.data;
      })
    },
    onSubmit() {
      let form = this.form;
      if (form.destnum == "" && form.sender == "" && (form.monthdate == "" || form.monthdate == null)) {
        ElMessage.error("发件人，收件人，日期不能全为空")
        return
      }
      SmsApiService.queryExList(form).then(res => {
        console.info(res.data)
        this.tableData = res.data;
      })
    },
  },
}
</script>

<style scoped>

</style>
