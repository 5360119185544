import {ApiService} from '../api'
import request from "@/http";

const SmsApiService = new ApiService('https://sms.chembasebio.com/sms/exsms');
//const SmsApiService = new ApiService('http://localhost/sms/exsms');


SmsApiService.SendSms = function (data) {
    return request({
        url: this.baseUrl + '/send',
        method: 'post',
        data: data
    })
}
SmsApiService.getByExnumber = function (data) {
    return request({
        url: this.baseUrl + '/getExinfo',
        method: 'post',
        data: data
    })
}
SmsApiService.getByCname = function (data) {
    return request({
        url: this.baseUrl + '/cname',
        method: 'post',
        data: data
    })
}
SmsApiService.queryExList = function (data) {
    return request({
        url: this.baseUrl + '/query',
        method: 'post',
        data: data
    })
}


SmsApiService.getDaySended = function (day) {
    return request({
        url: this.baseUrl + '/day',
        method: 'post',
        data: "date=" + day
    })
}
SmsApiService.getTodaySended = function (data) {
    return request({
        url: this.baseUrl + '/today',
        method: 'get',
        data: data
    })
}
export default SmsApiService
