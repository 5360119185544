import {ApiService} from '../api'
import request from "@/http";

//const ExpressApiService = new ApiService('http://localhost/sms/express');
const ExpressApiService = new ApiService('https://sms.chembasebio.com/sms/express');

ExpressApiService.getExpressList = function (data) {
    return request({
        url: this.baseUrl + '/list',
        method: 'post',
        data: "mobile=" + data
    })
}


ExpressApiService.addNewExpress = function (data) {
    return request({
        url: this.baseUrl + '/addEx',
        method: 'post',
        data: "exname=" + data
    })
}
ExpressApiService.updateExpress = function (data) {
    return request({
        url: this.baseUrl + '/update',
        method: 'post',
        data: data
    })

}
ExpressApiService.deleteExpress = function (data) {
    return request({
        url: this.baseUrl + '/delete',
        method: 'post',
        data: data
    })

}
export default ExpressApiService
