import {createApp} from 'vue'
import moment from 'moment' //导入文件
import App from './App.vue'
import store from './store'
import router from './router'

import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'

import zhCn from 'element-plus/es/locale/lang/zh-cn'

moment.locale('zh-cn');//需要汉化

const app = createApp(App)
app.config.globalProperties.$moment = moment;

app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router).use(store).mount('#app')
