<template>

  <el-row>
    <el-form ref="form" :inline="true" :model="form" label-width="120px">
      <el-form-item label="快递公司名称">
        <el-input id="exname" v-model="form.exname" style="width: 229px;"  placeholder="请输入要添加的快递公司名称" ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">添加快递</el-button>
        <el-button @click="resetinput">重置输入</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-row>
    <el-button type="primary" @click="refresh">
      <span style="vertical-align: middle;"> <i class="el-icon-refresh">刷新</i></span></el-button>
  </el-row>
  <el-table :data="exList" :default-sort="{ prop: 'sendtime', order: 'descending' }" border
            style="width: 100%">
    <el-table-column label="编号" prop="id" width="180"/>
    <el-table-column label="快递公司" prop="exname" width="180"/>
    <el-table-column label="状态" prop="status"/>
    <el-table-column label="Operations">
      <template #default="scope">
        <el-button
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
        >Delete
        </el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import ExpressApiService from "@/api/ExpressApi";
import {ElMessage, ElNotification} from "element-plus";

export default {
  name: "addExpree",
  data() {
    return {
      form: {
        exname: ""
      },
      exList: []
    }
  },
  methods: {
    resetinput() {
      this.form.exname = ""
    },
    refresh() {
      ExpressApiService.getExpressList().then(res => {
        this.exList = res.data;
      })
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
      ExpressApiService.deleteExpress(row).then(res => {
        ElNotification.success(res.data.message);
        this.refresh()
      })
    },
    onSubmit() {
      if (this.form.exname == "") {
        console.info("NULL exmame")
        ElMessage.error("快递公司名称不能为空")

        return
      }
      ExpressApiService.addNewExpress(this.form.exname).then(res => {
        console.info(res.data)
        ElNotification.success(res.data.message);
      })

    }

  }
}
</script>

<style scoped>

</style>