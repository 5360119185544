<template>
  <!--  <div id="nav">-->
  <!--    <router-link to="/">Home</router-link> |-->
  <!--    <router-link to="/about">About</router-link>-->
  <!--  </div>-->
  <el-container >
    <el-main v-bind:style="{minHeight: Height+'px'}">
      <router-view/>
    </el-main>
  <el-footer id="footer">
    <ul>
      <li>
        <el-row>
          <span style="color: #FFFFFF ">北京康倍斯科技有限公司&copy; 2021</span>
        </el-row>
      </li>
    </ul>
    <ul>
      <li>
        <span style="font-size: small">
          京ICP备13020982号
        </span>
      </li>
      </ul>


  </el-footer>
  </el-container>
</template>
<script>


export default {
  name: "Login",
  data(){
    return {
      Height: 0
    }
  },
  mounted(){
    //动态设置内容高度 让footer始终居底   header+footer的高度是100
    this.Height = document.documentElement.clientHeight - 60;
    window.onresize = ()=> {this.Height = document.documentElement.clientHeight -60}
  }
}
</script>
<style>

body{
  min-height:100%;
  margin:0;
  padding:0;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;

}

#footer{
  background:#2c3e50;
  color:#FFFFFF;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  text-align: center;
}
#footer ul {
  list-style:none;
  color: #FFFFFF;
  margin:0px;
}
#footer ul li{
  display:inline-block;
  color: #FFFFFF;
  text-align: center;
  padding: 5px 0px 0px 10px;
}
#footer .el-row{
  margin: 0 auto;
  width: 100%;
  lign-items:center;
  text-align: center;
  align-items: center;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
