import {ApiService} from '../api'
import request from "@/http";

const UserApiService = new ApiService('https://sms.chembasebio.com/sms/user');
//const UserApiService = new ApiService('http://localhost/sms/user');

UserApiService.login = function (data) {
    return request({
        url: this.baseUrl + '/login',
        method: 'post',
        data: data
    })
}

UserApiService.getsmscode = function (data) {
    return request({
        url: this.baseUrl + '/getcode',
        method: 'post',
        data: "mobile=" + data
    })
}

export default UserApiService
