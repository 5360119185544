import {createRouter, createWebHistory} from 'vue-router'
import LoginView from "@/views/LoginView";
import SendSms from "@/components/SendSms";
import listExpress from "@/components/listExpress";
import {getToken} from "@/utils/auth";
import addExpree from "@/components/express/addExpree";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            requireAuth: true
        },
        children: [
            {
                path: 'smsops',
                component: SendSms
            }
            ,
            {
                path: 'exops',
                component: listExpress
            }
            ,
            {
                path: 'addex',
                component: addExpree
            }
        ],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(res => res.meta.requireAuth)) {// 判断是否需要登录权限
        if (getToken()) {// 判断是否登录
            next()
        } else {// 没登录则跳转到登录界面
            next({
                path: '/',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})
export default router
