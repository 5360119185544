const TOKEN_KEY = 'cbs-sms-token'
const USER_KEY = 'cbs-sms-user'

// 获取 token
export function getToken() {
    return sessionStorage.getItem(TOKEN_KEY)
}

// 保存 token
export function setToken(token) {
    return sessionStorage.setItem(TOKEN_KEY, token)
}

// 获取用户信息
export function getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY))
}

//验证手机号码格式
export function checkPhone(number) {
    let phone = number
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
        // ElMessage.error("手机号码不合法，请重新输入");
        return false;
    } else {
        return true
    }
}

//保存用户信息
export function setUser(user) {
    sessionStorage.setItem(USER_KEY, JSON.stringify(user))
}

//移除用户信息
export function removeToken() {
    sessionStorage.removeItem(TOKEN_KEY)
    sessionStorage.removeItem(USER_KEY)
}
