<template>
  <el-row>
    <el-row>
      <el-button type="primary" @click="getTodaySendedSms">查询您今日已发送记录</el-button>
      <el-date-picker v-model="selectdate" format="YYYY/MM/DD"
                      placeholder="选择要查询的日期"
                      type="date"
                      value-format="YYYY-MM-DD" @change=changeDate>
      </el-date-picker>
    </el-row>
    <el-table :data="tableData" :default-sort="{ prop: 'sendtime', order: 'descending' }" border
              style="width: 100%">
      <el-table-column label="收件人姓名" prop="destname"/>
      <el-table-column label="收件人电话" prop="destnum"/>
      <el-table-column label="快递单号" prop="exnumber" width="180"/>
      <el-table-column label="快递公司" prop="excompany" width="180"/>

      <el-table-column :formatter=timeformat label="发送时间" prop="sendtime" sortable/>
      <el-table-column label="发送人" prop="sender"/>
    </el-table>
  </el-row>
</template>


<script>
import SmsApiService from "@/api/SmsApi"


/**
 *
 * "destnum":"13411111111",
 "excompany":"中通快递",
 "exnumber":"3333333",
 "id":11111111134,
 "monthdate":"2021-10-15",
 "sender":"caohaiwang",
 "sendtime":1634311312000
 * **/
export default {

  name: "TodaySended",
  data() {
    return {
      tableData: [],
      selectdate: ""
    }
  },
  methods: {
    timeformat: function (row, column, cellValue, index) {
      console.info(row, column, cellValue, index)
      return this.$moment(cellValue).format('YYYY MMMM Do, h:mm:ss a');
    },
    getTodaySendedSms() {
      SmsApiService.getTodaySended().then(res => {
        this.tableData = res.data;
      })
    },
    changeDate() {
      console.info(this.$data.selectdate)
      SmsApiService.getDaySended(this.selectdate).then(res => {
        this.tableData = res.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
