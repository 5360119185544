<template>
  <el-container>
    <el-main>
      <div style="margin: 0 auto;padding-top: 150px">
        <login id="login">
        </login>
      </div>
    </el-main>

  </el-container>
</template>

<script>
import Login from '@/components/Login.vue'
export default {
  name: "LoginView",
  components: {

    Login
  }
}
</script>

<style scoped>
.el-col {
  border-radius: 4px;
}

#login {

  background: #FFF;
  margin: 0 auto;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

body {
  background: #2c3e50;
}
</style>
