<template>
  <el-card class="box-card" style="width: 500px">
    <template #header>
      <div class="card-header" style="padding: 10px">
        <span>用户登录</span>
      </div>
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="登录号码">
          <el-input v-model="form.mobile" placeholder="请输入您的登录手机号码"></el-input>
        </el-form-item>

        <el-form-item label="短信验证">
          <el-input v-model="form.smscode" label="Zone one" placeholder="请输入短信验证码"
                    style="width: 60%">
          </el-input>
          <el-button :disabled="disabled" style="width: 40%" type="primary" @click="onsendsms()">{{ content }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">LOGIN</el-button>
          <el-button @click="onReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </template>

  </el-card>
</template>

<script>
import {ElMessage} from 'element-plus'
import userapiservice from "../api/UserApiService"
import {setToken, setUser} from "@/utils/auth";


export default {
  name: "Login",
  data() {
    return {
      form: {
        mobile: '',
        smscode: "",
      },
      info: "",
      disabled: false,
      total: 60,
      content: "获取验证码",
    }
  },
  methods: {
    onReset() {
      var that = this;
      that.$data.form.mobile = "";
      that.$data.form.smscode = "";
    },
    onsendsms() {
      let that = this;
      let phone = that.$data.form.mobile
      let phoneok = that.checkPhone(phone)
      if (phoneok) {
        userapiservice.getsmscode(that.$data.form.mobile).then(res => {
          ElMessage.success(res.data.message);
          this.disabled = true
          this.countDown()
        }).catch(e => {
          console.info(e)
        })
      } else {
        console.info(phone, "is not a regular number")
      }
      console.info("Send SMS ")
    },
    onSubmit() {
      let that = this;
      console.log('submit!')
      let smscode = that.$data.form.smscode;
      let mobile = that.$data.form.mobile;
      if (mobile === "") {
        ElMessage.warning("请输入登录手机号码");
        return
      }
      if (smscode === "") {
        ElMessage.warning("请输入验证码");
        return
      }
      console.info(smscode, mobile)
      userapiservice.login("mobile=" + mobile + "&smscode=" + smscode).then(res => {
        ElMessage.success("登录成功");
        console.info(res.data)
        setToken(res.data.token);
        setUser(res.data.name)

        let redirect = this.$route.query.redirect;
        if (redirect) {
          this.$router.push({path: redirect});
        } else {
          this.$router.push({path: "/home"});
        }

      }).catch(e => {
        console.info(e)
      })
    },
    checkPhone(number) {
      let phone = number
      if (!(/^1[3456789]\d{9}$/.test(phone))) {
        ElMessage.error("手机号码不合法，请重新输入");
        return false;
      } else {
        return true
      }
    },
    countDown() {
      let clock = window.setInterval(() => {
        this.total--
        this.content = this.total + 's后重新发送'
        if (this.total < 0) {
          window.clearInterval(clock)
          this.content = '重新发送验证码'
          this.total = 60
          this.disabled = false  //这里重新开启
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
}
</style>
