import axios from 'axios'
import {ElMessage} from 'element-plus'
import {getToken} from "@/utils/auth";
// 创建axios实例
const service = axios.create({
    // baseURL: process.env.BASE_API, // api的base_url
    timeout: 30000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
    //此处进行token等数据处理
    const token = getToken()
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.token = token  //请求头加上token
    }
    return config
}, error => {
    // Do something with request error
    Promise.reject(error)
});

// respone拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        console.info(response)
        if (response.status != 200) {
            //此处进行异常处理
            console.info(res)
            ElMessage.error(response.data.message);
            return Promise.reject(res);
        }
        return response
    },
    error => {
        //此处进行异常处理
        console.info(error.response)
        ElMessage.error(error.response.data.message);
        return Promise.reject(error)
    }
);

export default service
